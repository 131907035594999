// 全局loading
import Vue from 'vue'
import { Spin } from 'ant-design-vue'

let instance = null
const style = {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, .5)',
    zIndex: 99999
}
let timeoutId = null
const getInstance = () => {
    // Vue.extend创建子类
    const Component = Vue.extend({
        data() {
            return {
                show: false,
                message: 'Loading...'
            }
        },
        render(h) {
            return this.show ? 
                h('div', { style }, [h(Spin, { props: { tip: this.message } })]) 
                : null
        },
        methods: {
            loading(val, timeout) {
                this.show = true
                this.message = val || 'Loading...'
                if (timeout) {
                    timeoutId = setTimeout(() => {
                        this.close()
                    }, timeout)
                }
            },
            close() {
                // 清除定时器
                clearTimeout(timeoutId)
                timeoutId = null
                this.show = false
            }
        },
        destroyed() {
            if (instance && instance.$el) {
                // 当组件卸载时，从DOM中移除元素以避免内存泄露
                instance.$el.remove()
            }
        }
    })
    if (!instance) {
        // 生成子类的实例
        instance = new Component()
        instance.$mount()
        document.body.appendChild(instance.$el)
    }
    return instance
}
export default {
    ...Spin,
    show(val, timeout) {
        getInstance().loading(val, timeout)
    },
    hide() {
        getInstance().close()
    }
}