import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/login/login.vue'
import Demo from '@/login/demo.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  // {
  //   path: '/',
  //   name: 'demo',
  //   component: Demo,
  // },
]

const router = new VueRouter({
  mode: 'history',
  // mode: 'host',
  base: process.env.BASE_URL,
  routes
})

export default router
