import request from '@/utils/request'

//地区列表  
export function getRegister(form) {
    return request({
        method: 'POST',
        url: '/register',
        params: {
            u: form.username,
            p: form.newPasswordConfig,
            m: form.randomNumber,
            pt: form.type,
            st: form.score,
            c: form.code,
        },
    })
}
