<template>
  <div class="demo">
    <div
      class="blue-gradient g-10"
      style="
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
          rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
      "
    ></div>
    <div
      class="blue-gradient g-9"
      style="
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
          rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
      "
    ></div>
    <div
      class="purple-gradient grad-14new"
      style="
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
          rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
      "
    ></div>
  </div>
</template>
  
  <script>
export default {
  name: "demo",
  props: {},
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>
  
  <style scoped lang="less">
@media screen and (min-width: 1920px) {
  .blue-gradient .g-10 {
    filter: blur(250px);
    width: 400px;
    height: 400px;
    bottom: 7%;
    right: 16%;
  }
  .blue-gradient {
    right: 13%;
  }
  .purple-gradient .grad-14new {
    width: 400px;
    height: 400px;
    top: 20%;
    bottom: auto;
    left: 0%;
    right: auto;
  }
  .purple-gradient {
    filter: blur(200px);
    width: 270px;
    height: 270px;
    left: 16%;
  }
}
@media screen and (min-width: 1440px) {
  .blue-gradient .g-10 {
    bottom: 9%;
    right: 5%;
  }
  .purple-gradient {
    top: -10%;
    left: 8%;
  }
}
@media screen and (min-width: 1280px) {
  .purple-gradient {
    top: -10%;
  }
}
.blue-gradient .g-10 {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(
    to bottom,
    var(--primary-blue),
    #ff00c3 26%,
    var(--primary-color) 65%,
    var(--primary-blue)
  );
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(
    to bottom,
    var(--primary-blue),
    #ff00c3 26%,
    var(--primary-color) 65%,
    var(--primary-blue)
  );
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}
:root {
  --black: black;
  --white: white;
  --white-50: rgba(255, 255, 255, 0.5);
  --primary-color: #90f;
  --primary-blue: #3f0efa;
  --dark-gray: #afafaf;
  --white-10: rgba(255, 255, 255, 0.1);
  --white-20: rgba(255, 255, 255, 0.2);
  --white-5-51: rgba(255, 255, 255, 0.05);
  --dark-gray-2: #222;
  --gray: #666;
  --cyan: #00eaff;
  --medium-orchid: #b5f;
}
.blue-gradient .g-9 {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(
    to bottom,
    var(--primary-blue),
    #ff00c3 26%,
    var(--primary-color) 65%,
    var(--primary-blue)
  );
  top: 14%;
  bottom: auto;
  left: 26%;
  right: auto;
}
.blue-gradient {
  filter: blur(150px);
  background-color: #3f0efa;
  border-radius: 100%;
  width: 200px;
  height: 200px;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}
.purple-gradient .grad-14new {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(
    to bottom,
    var(--primary-color) 40%,
    #086efc 75%,
    var(--primary-blue)
  );
  width: 300px;
  height: 300px;
  top: 19%;
  bottom: auto;
  left: -7%;
  right: auto;
}
.purple-gradient {
  filter: blur(180px);
  background-color: #90f;
  border-radius: 100%;
  width: 250px;
  height: 250px;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
}
.blue-gradient.g-9 {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(
    to bottom,
    var(--primary-blue),
    #ff00c3 26%,
    var(--primary-color) 65%,
    var(--primary-blue)
  );
  top: 14%;
  bottom: auto;
  left: 26%;
  right: auto;
}
.blue-gradient.g-10 {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(
    to bottom,
    var(--primary-blue),
    #ff00c3 26%,
    var(--primary-color) 65%,
    var(--primary-blue)
  );
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(
    to bottom,
    var(--primary-blue),
    #ff00c3 26%,
    var(--primary-color) 65%,
    var(--primary-blue)
  );
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}
</style>
  