import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
let i18n = new VueI18n({
    // locale: 'en', // 语言标识
    locale: localStorage.getItem('langs') || 'zh', // 语言标识
    messages: {
        'zh': Object.assign(require('./zh').default),   // 中文语言包
        'en': Object.assign(require('./en').default),   // 英文语言包
    },
    silentTranslationWarn: true,
})
export default i18n